* {
  font-family: 'Montserrat', sans-serif;
}
p {
  padding: 0;
  margin: 0;
}

.proceedButton {
  background: #2b538e;
  width: 168px;
  height: 48px;
  padding: 8px 40px;
  gap: 10px;
  border-radius: 8px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
}

.retakeButton {
  background: white;
  width: 207px;
  height: 48px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #2b538e;
  color: #2b538e;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.H1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 39.01px;
  text-align: left;
}

.H2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 34.13px;
  text-align: left;
}

.H3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 29.26px;
  text-align: left;
}

.H4 {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.82px;
  text-align: left;
}

.H5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
}

.H6 {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  text-align: left;
}

.body1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
}

.body2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
}

.caption {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
}

.proceedButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button {
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  text-align: left;
}

select {
  -webkit-appearance: none;
  appearance: none;
  padding-left: 10px;
}

select {
  background-image: url('./Assets//Images/bottomArrow.svg');
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
}

.verticalLine {
  height: 79px;
  width: 1px;
  border: none;
  outline: none;
  background-color: #E5E4F4;
}